import { render, staticRenderFns } from "./task-telegram-dialog.vue?vue&type=template&id=f1bd18c8&scoped=true&"
import script from "./task-telegram-dialog.vue?vue&type=script&lang=ts&"
export * from "./task-telegram-dialog.vue?vue&type=script&lang=ts&"
import style0 from "./task-telegram-dialog.vue?vue&type=style&index=0&id=f1bd18c8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1bd18c8",
  null
  
)

export default component.exports