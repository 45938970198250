
















































































































































































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject, Ref } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import TokenIcon from '@/components/images/token-icon.vue'
import VerticalStepper from '@/components/vertical-stepper.vue'
import { IdoApplyViewModel } from '../viewmodels/ido-apply-viewmodel'

@Observer
@Component({
  components: {
    TokenIcon,
    VerticalStepper,
  },
})
export default class StakingLPDialog extends Vue {
  @Inject() vm!: IdoApplyViewModel
  walletStore = walletStore
  retweetLink1 = ''
  retweetLink2 = ''
  count = 0
  @Ref('inputRef') inputRef: any

  submitLink1() {
    if (!this.vm.checkRetweet1Link(this.retweetLink1)) return
    this.vm.submitRetweet1(this.retweetLink1)
    this.retweetLink1 = ''
    this.retweetLink2 = ''
    this.inputRef.reset()
  }
  submitLink2() {
    if (!this.vm.checkRetweet2Link(this.retweetLink2)) return
    this.vm.submitRetweet2(this.retweetLink2)
  }
}
