







































































































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import TokenIcon from '@/components/images/token-icon.vue'
import VerticalStepper from '@/components/vertical-stepper.vue'
import { IdoApplyViewModel } from '../viewmodels/ido-apply-viewmodel'

@Observer
@Component({
  components: {
    TokenIcon,
    VerticalStepper,
    'vue-telegram-login': () => import('./vue-telegram-login.vue'),
  },
})
export default class StakingLPDialog extends Vue {
  @Inject() vm!: IdoApplyViewModel
  walletStore = walletStore
  async handleLoginTelegram(user) {
    await this.vm.handleLoginTelegram(user)
  }
}
