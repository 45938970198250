import { FarmContractSolana } from '@/blockchainHandlers/farm-contract-solana'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { tierHelper } from '@/constants'
import { ApplyModel } from '@/models/apply-model'
import { apiService } from '@/services/api-service'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import {
  MessageSignerWalletAdapter,
  SignerWalletAdapter,
  WalletAdapter,
  WalletNotFoundError,
  WalletSignTransactionError,
  WalletTimeoutError,
  WalletWindowClosedError,
} from '@solana/wallet-adapter-base'
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  Wallet,
} from '@solana/wallet-adapter-wallets'
import { first, get, map } from 'lodash'
import { action, computed, IReactionDisposer, observable, reaction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import Web3 from 'web3'
import { PoolStore } from '../stores/pool-store'
import { poolsStore } from '../stores/pools-store'

export class IdoApplyViewModel {
  @observable jwtJson = ''
  @observable unicodeName = ''
  @observable telegramUser = ''
  @observable refLinkFirst = ''
  @observable refLinkSecond = ''
  @observable currentStep = 0
  @observable referralCount = 0

  @observable userTier = 'No Tier'
  @observable tierType = 0
  @observable tierImage: string | null = ''
  @observable farmStakedAmount = FixedNumber.from('0')
  @observable apply: ApplyModel | any = null
  @observable poolStore?: PoolStore

  @observable registerDialog = false
  @observable telegramTaskDialog = false
  @observable twitterTaskDialog = false
  @observable discordTaskDialog = false

  @observable loadingPool = false
  @observable loadingTelegramDialog = false
  @observable loadingTwitterDialog = false
  @observable loadingDiscordDialog = false
  @observable loadingRegisterDialog = false
  @observable loadingCounter = 0
  @observable showConnectSolanaDialog = false
  @observable crosschainAccount = ''
  @observable retweet1LinkError = ''
  @observable retweetLink1 = ''
  @observable retweetLink2 = ''
  @observable retweet2LinkError = ''
  @observable hasTierCheckbox = false
  @observable solWalletItems = [getPhantomWallet(), getSolletExtensionWallet(), getSolletWallet(), getSolflareWallet()]
  @observable selectedWallet: Wallet | null = null
  @observable selectedAdapter: WalletAdapter | SignerWalletAdapter | MessageSignerWalletAdapter | null = null
  @observable tierSignature = ''
  @observable showconfirmConfigRegisterDialog = false
  @observable userInfo: any = undefined
  @observable investor: any = undefined
  @observable sessionId: any = ''
  @observable isConnectingKycNetwork = false
  @observable isLedgerTierUser = false
  @observable enableNextStepDiscord = false

  @observable isGuaranteeTierNextStep = false

  // social task state
  @observable poolCommunityTelegramState = false
  @observable poolAnnouncementTelegramState = false
  @observable waggleCommunityTelegramState = false
  @observable waggleAnnouncementTelegramState = false
  @observable waggleInsightsTelegramState = false
  @observable poolTwitterState = false
  @observable waggleTwitterState = false
  @observable waggleInsightsTwitterState = false

  _disposers: IReactionDisposer[] = []
  destroy() {
    this._disposers.forEach((d) => d())
  }

  constructor() {
    this._disposers = [
      reaction(
        () => walletStore.account,
        (x) => {
          if (!x) this.resetCache()
          if (x) {
            this.onLoadingPage()
          }
        },
        { fireImmediately: true }
      ),
      reaction(
        () => this.crosschainAccount,
        () => {
          this.resetSignedMessage()
        },
        { fireImmediately: true }
      ),
    ]
  }

  @action getServerError(functionName, err) {
    let message
    if (err && typeof err === 'string') message = err
    else {
      message = err?.message || err?.msg || 'Please try again'
    }
    const apiError = get(err, 'response.data.message')
    console.error(apiError, err)
    if (apiError) {
      if (apiError instanceof Array) {
        let errMsg = first(apiError)
        if (errMsg) errMsg = errMsg.messages
        if (errMsg) errMsg = first(errMsg)
        if (errMsg) errMsg = errMsg.message
        if (errMsg) message = errMsg
      } else if (apiError instanceof String || typeof apiError === 'string') {
        message = apiError as string
      }
    }

    return `${functionName}: ${message}`
  }

  @computed get synapsStates() {
    return this.investor?.synapsStates || {}
  }

  @computed get isKycSubmitted() {
    return !!this.investor?.status
  }

  @computed get lastUpdateState() {
    return this.investor?.data?.lastUpdateState
  }

  @computed get isValidKycPassTime() {
    if (!this.lastUpdateState || this.forcePassKyc) return true
    return moment(+this.lastUpdateState).isBefore(this.kycEndDate)
  }
  @computed get isValidExtendKycPassTime() {
    if (!this.lastUpdateState || this.forcePassKyc) return true
    return moment(+this.lastUpdateState).isBefore(this.extendKycEndDate)
  }
  @computed get isWinnerNoStaking() {
    return this.apply && this.apply.isCommunityRound && !this.apply.isTierRound
  }

  @computed get forcePassKyc() {
    return this.investor?.data?.forcePassKyc
  }

  @computed get kycRejectedReasons() {
    const synapsStates = map(Object.values(this.synapsStates), 'reason')
    return synapsStates.filter((x) => !!x)
  }

  @computed get isKycPending() {
    return this.investor?.status === 'kyc-pending'
  }
  @computed get isKycFinalRejected() {
    return this.investor?.status === 'kyc-banned'
  }
  @computed get isKycVerified() {
    return this.investor?.status === 'kyc-verified'
  }

  @action telegramChanelSupport() {
    window.open('https://t.me/wagglenetwork', '_blank')
  }
  @action customerSupport() {
    window.open('https://t.me/anhdt17', '_blank')
  }

  //new flow
  @action resetSignedMessage() {
    this.tierSignature = ''
    this.isLedgerTierUser = false
    if (walletStore.chainType !== 'sol') this.tierType = 0
  }

  @action.bound
  changeShowConnectSolanaDialog(value) {
    this.showConnectSolanaDialog = value
  }

  @asyncAction *signMessage(account, chainType, nonce, selectedAdapter: any = null) {
    if (!account) return ''
    const message = `https://app.waggle.network/projects wants to: \n Sign message with account \n ${account} - One time nonce: ${nonce}`
    const data = new TextEncoder().encode(message)
    if (chainType === 'sol') {
      //solana sign message
      const a = (selectedAdapter || this.selectedAdapter) as any
      let res
      if (a.signMessage) {
        res = yield a.signMessage(data)
      } else {
        res = yield a._wallet.signMessage(data)
      }
      return Object.values(res?.signature || res)
    } else {
      //bsc sign message
      if (typeof window === 'undefined') {
        return ''
      }
      if (window.ethereum) {
        const request = { method: 'personal_sign', params: [message, account] }
        return yield window.ethereum.request(request)
      } else {
        throw new Error('Plugin Metamask is not installed!')
      }
    }
  }

  // @asyncAction *kycOnSignMessage() {
  //   try {
  //     loadingController.increaseRequest()
  //     yield this.registerCrosschainUser()
  //     this.kycSignature = yield this.signMessage(
  //       this.crosschainAccount,
  //       this.passedKycNetwork,
  //       this.userInfo?.nonce || ''
  //     )
  //     const isValidKycAddress = yield apiService.investors.checkKycAddresss({
  //       walletAddress: walletStore.account,
  //       kycAddress: this.crosschainAccount,
  //       kycSignature: this.kycSignature,
  //     })
  //     if (isValidKycAddress) {
  //       this.passedKycCheckbox = true
  //       this.signedKycAddress = true
  //       snackController.success('Confirm ownership successfully!')
  //     } else {
  //       snackController.error('Error occured when confirm ownership!')
  //     }
  //   } catch (e) {
  //     // try {
  //     //   const errorMsg = this.getServerError('kycOnSignMessage', e)
  //     //   apiService.logs.create({
  //     //     investor: this.investor?.id,
  //     //     user: this.userInfo?.id,
  //     //     error: errorMsg,
  //     //     data: JSON.stringify(e),
  //     //   })
  //     // } catch (err) {
  //     //   // TODO
  //     // }
  //     snackController.commonError(e)
  //   } finally {
  //     loadingController.decreaseRequest()
  //   }
  // }

  @action requestRegisterWhitelist() {
    if (this.hasTierCheckbox) this.showconfirmConfigRegisterDialog = true
    else {
      this.changeRegisterDialog(true)
    }
  }

  @asyncAction *registerWhitelist() {
    try {
      this.loadingRegisterDialog = true
      const referralId = this.refLinkFirst === 'whitelist' && this.refLinkSecond ? this.refLinkSecond : null
      const data = {
        referralLink: `https://${
          process.env.NODE_ENV === 'production' ? 'app.waggle.network' : 'dev.waggle.network'
        }/project/${this.unicodeName}/whitelist?ref=${this.userInfo?.id}`,
      }
      const payload: any = {
        investorId: this.investor?.id,
        chainId: this.pool?.chainId,
        data,
        referralUser: referralId,
        poolId: this.poolId,
        walletAddress: walletStore.account,
      }
      if (this.tierCheckBoxDone && this.tierType) {
        payload.tierAddress = this.crosschainAccount
        if (!this.isLedgerTierUser) payload.tierSignature = this.tierSignature
      }
      if (this.tierType >= 3) {
        payload.status = 'whitelisted'
      }
      this.apply = yield apiService.applies.create(payload)
      snackController.success('Register whitelist successfully!')
      this.changeRegisterDialog(false)
    } catch (e: any) {
      snackController.commonError(e)
      try {
        const errorMsg = this.getServerError('registerWhitelist', e)
        apiService.logs.create({
          investor: this.investor?.id,
          user: this.userInfo?.id,
          error: errorMsg,
          data: JSON.stringify(e),
        })
      } catch (err) {
        // TODO
      }
    } finally {
      this.loadingRegisterDialog = false
    }
  }

  @asyncAction *tierOnSignMessage() {
    try {
      loadingController.increaseRequest()
      const crosschainAccount = yield this.registerCrosschainUser()
      this.isLedgerTierUser = crosschainAccount?.data?.isLedger ? true : false
      if (!this.isLedgerTierUser) {
        this.tierSignature = yield this.signMessage(this.crosschainAccount, 'sol', crosschainAccount.nonce || '')
      }
      const payload: any = {
        poolId: this.poolId,
        walletAddress: walletStore.account,
        tierAddress: this.crosschainAccount,
      }
      if (!this.isLedgerTierUser) {
        payload.tierSignature = this.tierSignature
      }

      const userTier = yield apiService.investors.checkTierAddress(payload)
      if (!userTier) return
      else snackController.success('Confirm ownership successfully!')
      this.tierType = userTier.tier ? userTier.tier : 0
      const lst = tierHelper.getTierList()
      const ter: any = lst.reverse().find((x) => x.tierIndex === this.tierType) || {}
      this.userTier = ter?.name || 'Staked'
      if (ter.image) {
        this.tierImage = ter.image
      } else {
        this.tierImage = null
      }
    } catch (e) {
      if (e instanceof WalletSignTransactionError) {
        snackController.error('User rejected the request')
      } else snackController.commonError(e)
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @asyncAction *connectSolana(wallet) {
    try {
      loadingController.increaseRequest()
      yield this.disconnectSolana()
      const adapter = wallet.adapter()
      if (!adapter) return
      if (!adapter.connected) yield adapter.connect()
      this.selectedWallet = wallet
      this.selectedAdapter = adapter
      this.crosschainAccount = adapter.publicKey?.toString() || ''
      ;(adapter as any).on('disconnect', () => this.disconnectSolana())
      ;(adapter as any).on('error', (err) => snackController.error(`${err.name} ${err.message}`))
      this.changeShowConnectSolanaDialog(false)
      return true
    } catch (error) {
      if (error instanceof WalletNotFoundError) {
        snackController.error(`Plugin ${wallet.name} is not installed!`)
      } else if (error instanceof WalletWindowClosedError) {
        snackController.error(`The wallet window is closed!`)
      } else if (error instanceof WalletTimeoutError) snackController.error('Wallet timeout error!')
      else snackController.error(error as any)
      console.error(error)
      return false
    } finally {
      loadingController.decreaseRequest()
    }
  }
  @asyncAction *disconnectSolana() {
    const adapter = this.selectedAdapter
    if (adapter) {
      this.crosschainAccount = ''
      this.selectedAdapter = null
      this.selectedWallet = null
      adapter.removeAllListeners('disconnect')
      adapter.removeAllListeners('error')
      adapter.removeAllListeners()
      yield adapter.disconnect().catch()
    }
  }

  @action changeHasTierCheckbox(value) {
    this.hasTierCheckbox = value
  }
  // @asyncAction *connectKycNetwork() {
  //   if (this.passedKycNetwork === 'sol') {
  //     this.changeShowConnectSolanaDialog(true)
  //     //TODO: add solana check kyc
  //   } else {
  //     if (typeof window === 'undefined') {
  //       return false
  //     }
  //     const eth: any = window.ethereum
  //     if (eth) {
  //       try {
  //         this.isConnectingKycNetwork = true
  //         yield eth.request({ method: 'eth_requestAccounts' })
  //         const accounts = yield window.ethereum.request({ method: 'eth_accounts' })
  //         eth.removeListener('accountsChanged', this.ethereumConfigChanged)
  //         eth.on('accountsChanged', this.ethereumConfigChanged)
  //         this.crosschainAccount = accounts.length ? Web3.utils.toChecksumAddress(accounts[0]) : ''
  //         return true
  //       } catch (e) {
  //         snackController.error(e.message || e.msg)
  //       } finally {
  //         this.isConnectingKycNetwork = false
  //       }
  //     } else {
  //       throw new Error('Plugin Metamask is not installed!')
  //     }
  //     return false
  //   }
  // }
  ethereumConfigChanged = (account) => {
    const currentAccount = (account || [])[0] || ''
    if (!currentAccount) return
    this.crosschainAccount = Web3.utils.toChecksumAddress(currentAccount)
  };

  @asyncAction *registerCrosschainUser() {
    let users
    try {
      users = yield apiService.users.find({ username: this.crosschainAccount }, { _limit: 1 }) || []
    } catch (e) {
      //
    }
    let user = users[0]
    if (!user) {
      user = yield apiService.users.signUp(this.crosschainAccount)
    }
    return user
  }

  @computed get isGuaranteeTier() {
    return this.apply && this.tierType >= 3
  }

  @action.bound changeConfirmConfigRegisterDialog(value) {
    this.showconfirmConfigRegisterDialog = value
  }

  @computed get nonce() {
    return this.investor?.nonce
  }

  @computed get correctConnection() {
    return !walletStore.walletConnected || !this.correctChain
  }

  @computed get visibleTierCheckbox() {
    return walletStore.chainType !== 'sol'
  }

  @computed get tierCheckBoxDone() {
    return this.hasTierCheckbox && this.tierType > 0
  }

  @computed get canRegisterWithTier() {
    return !this.hasTierCheckbox || this.tierCheckBoxDone
  }

  //old flow
  @action onLoadingPage() {
    this.loadingCounter++
    Promise.all([this.resetCache(), this.loadFarm(), this.fetchApply()]).finally(() => {
      this.loadingCounter = this.loadingCounter > 0 ? this.loadingCounter - 1 : 0
    })
  }

  @action resetCache() {
    this.jwtJson = ''
    this.userInfo = undefined
    this.investor = undefined
    this.telegramUser = ''
    this.tierType = 0
    this.apply = null
    this.userTier = 'No Tier'
    this.farmStakedAmount = FixedNumber.from('0')
    this.tierImage = null
    this.hasTierCheckbox = false
    walletStore.changeJwt('')
    this.crosschainAccount = ''
  }

  @asyncAction *login() {
    if (!this.isLogin && walletStore.account && this.correctChain) {
      walletStore.resetJwt()
      let getUser: any = []
      try {
        getUser = yield apiService.users.find({ username: walletStore.account }, { _limit: 1 }) || []
      } catch (e) {
        //
      }
      let user = getUser[0]
      if (!user) {
        user = yield apiService.users.signUp(walletStore.account)
      }
      const isLedger = user?.data?.isLedger
      let signature
      if (!isLedger) {
        try {
          signature = yield this.signMessage(
            walletStore.account,
            walletStore.chainType,
            user?.nonce,
            walletStore.selectedAdapter
          )
        } catch (e) {
          yield walletStore.disconnectAccount()
        }
        if (!signature) return
      } else signature = (Math.random() + 1).toString(36).substring(2)
      try {
        const res = yield apiService.users.signIn({
          publicAddress: walletStore.account,
          signature,
        })
        if (res) {
          this.userInfo = res.user
          this.investor = res.investor
          this.jwtJson = res.jwt
          walletStore.changeJwt(res.jwt)
        }
      } catch (e: any) {
        if (walletStore.chainType === 'sol') {
          walletStore.disconnectSolana()
          const errorMsg = e.msg || e.message
          if (errorMsg === 'Signature verification failed') {
            e.message =
              e.message + '. Please check if the connected wallet matches the wallet that is signing this message.'
          } else if (get(e, 'response.data.message') === 'Signature verification failed') {
            e.response.data.message +=
              '. Please check if the connected wallet matches the wallet that is signing this message.'
          }
        }

        this.apply = null
        snackController.commonError(e)
      }
    }
  }

  @asyncAction *loadPool(param) {
    this.loadingPool = true
    const { unicodeName, refLinkFirst, refLinkSecond } = param
    try {
      this.unicodeName = unicodeName
      this.poolStore = yield poolsStore.findPool(unicodeName)
      this.refLinkFirst = refLinkFirst
      this.refLinkSecond = refLinkSecond
      yield this.poolStore?.loadData()
    } catch (e: any) {
      console.log('load pool error', e.message | e.msg)
    } finally {
      this.loadingPool = false
    }
  }

  @asyncAction *loadFarm() {
    try {
      if (walletStore.account && walletStore.connectedSolProvider) {
        const handler = FarmContractSolana.getInstance()
        yield handler.load()
        const { amount } = yield handler.getUserInfo(walletStore.account)
        this.farmStakedAmount = amount
        const lst = tierHelper.getTierList()
        const ter: any = lst.reverse().find((x) => this.farmStakedAmount.toUnsafeFloat() >= x.amount) || {}
        this.tierType = ter?.tierIndex || 0
        if (this.tierType >= 3 && this.apply && this.investor && this.apply.status !== 'whitelisted') {
          const apply = yield apiService.applies.update(this.apply?.id, {
            investorId: this.investor?.id,
            status: 'whitelisted',
          })
          this.changeModel(apply)
        }
        this.userTier = ter?.name || 'Staked'
        if (ter.image) {
          this.tierImage = ter.image
        } else {
          this.tierImage = null
        }
      } else {
        this.farmStakedAmount = FixedNumber.from('0')
        this.tierImage = null
      }
    } catch (e) {
      console.error(e)
    }
  }

  @action setTier(tierType: number) {
    const tierInfo: any = tierHelper.getTierList().find((item) => item.tierIndex === tierType)
    this.tierType = tierType
    this.userTier = tierInfo?.name || 'Staked'
    if (tierInfo?.image) {
      this.tierImage = tierInfo.image
    } else {
      this.tierImage = null
    }
  }

  @action changeModel(apply) {
    this.apply = apply
  }

  @asyncAction *fetchApply() {
    try {
      yield when(() => !!this.poolStore)
      yield this.login()
      if (!this.isLogin) return
      const getApply = yield apiService.applies.find(
        {
          pool: this.poolId,
          investor: this.investor?.id,
          walletAddress: walletStore.account,
        },
        { _limit: 1 }
      ) || []
      let apply = getApply[0]
      const tierAddress = apply?.tierUser?.username
      if (tierAddress && walletStore.chainType !== 'sol') {
        const getTier = yield apiService.applies.getTier(tierAddress)
        this.setTier(getTier || 0)
      }
      if (this.tierType >= 3 && this.investor && apply && apply.status !== 'whitelisted') {
        apply = yield apiService.applies.update(apply?.id, {
          investorId: this.investor?.id,
          status: 'whitelisted',
        })
      }
      this.changeModel(apply)
      yield this.fetchReferral()
    } catch (e: any) {
      console.log('error fetchApply', e.message || e.msg)
    }
  }

  @action guaranteeTierNextStepOnChange(value) {
    this.isGuaranteeTierNextStep = value
  }

  @asyncAction *fetchReferral() {
    try {
      const referralCount1 = yield apiService.applies.count({
        pool: this.poolId,
        referralUser: this.userInfo?.id,
        status: 'done-tasks',
      })
      const referralCount2 = yield apiService.applies.count({
        pool: this.poolId,
        referralUser: this.userInfo?.id,
        status: 'whitelisted',
      })
      this.referralCount = referralCount1 + referralCount2
    } catch (e) {
      // console.log('error fetchApply', e.message || e.msg)
    }
  }

  @action.bound changeRegisterDialog(value: boolean) {
    this.registerDialog = value
  }

  @action.bound nextStep() {
    this.currentStep += 1
  }

  @action handleLoginTelegram(user) {
    if (user) {
      const telegramUser = `${user.first_name}_${user.id}`
      this.telegramUser = telegramUser
    }
    snackController.success('Login telegram success')
  }

  @action.bound changeTelegramTaskDialog(value: boolean) {
    if (value) {
      this.resetSocialTaskState()
      this.currentStep = 0
    }
    this.telegramTaskDialog = value
  }
  @action.bound changeDiscordTaskDialog(value: boolean) {
    if (value) {
      this.currentStep = 0
    }
    this.discordTaskDialog = value
  }

  @action.bound changeTwitterTaskDialog(value: boolean) {
    if (value) {
      this.resetSocialTaskState()
      this.currentStep = 0
      this.retweet1LinkError = ''
      this.retweet2LinkError = ''
      this.retweetLink1 = ''
      this.retweetLink2 = ''
    }
    this.twitterTaskDialog = value
  }

  @action resetSocialTaskState() {
    this.poolCommunityTelegramState = false
    this.poolAnnouncementTelegramState = false
    this.waggleCommunityTelegramState = false
    this.waggleAnnouncementTelegramState = false
    this.waggleInsightsTelegramState = false
    this.poolTwitterState = false
    this.waggleTwitterState = false
    this.waggleInsightsTwitterState = false
  }

  @asyncAction *submitDiscordTask() {
    try {
      this.loadingDiscordDialog = true
      const tasks = get(this.apply, 'tasks')
      const remainingStatus = tasks?.telegram?.isDone && tasks?.twitter?.isDone
      const apply = yield apiService.applies.update(this.apply?.id, {
        status: remainingStatus ? 'done-tasks' : this.apply.status,
        investorId: this.investor?.id,
        tasks: { ...tasks, discord: { isDone: true } },
      })
      this.changeModel(apply)
      yield this.fetchReferral()
      this.changeDiscordTaskDialog(false)
      snackController.success('Done Discord task!')
    } catch (e: any) {
      try {
        const errorMsg = this.getServerError('submitDiscord', e)
        apiService.logs.create({
          investor: this.investor?.id,
          user: this.userInfo?.id,
          error: errorMsg,
          data: JSON.stringify(e),
        })
      } catch (err) {
        // TODO
      }
      snackController.commonError(e)
    } finally {
      this.loadingTwitterDialog = false
    }
  }

  @action.bound joinTelegramCommunity() {
    this.poolCommunityTelegramState = true
    window.open(`${this.telegram}`, '_blank')
  }
  @action.bound joinTelegramAnnouncement() {
    this.poolAnnouncementTelegramState = true
    window.open(`${this.announcementTelegram}`, '_blank')
  }
  @action.bound joinWaggleCommunity() {
    this.waggleCommunityTelegramState = true
    window.open(`https://t.me/wagglenetwork`, '_blank')
  }
  @action.bound joinWaggleAnnouncement() {
    this.waggleAnnouncementTelegramState = true
    window.open(`https://t.me/waggle_network`, '_blank')
  }
  @action.bound joinWaggleInsightsTelegram() {
    this.waggleInsightsTelegramState = true
    window.open(`https://t.me/waggleinsights`, '_blank')
  }
  @action.bound joinWaggleInsightsTwitter() {
    this.waggleInsightsTwitterState = true
    window.open(`https://twitter.com/waggleinsights`, '_blank')
  }

  @action.bound joinTwitter() {
    this.poolTwitterState = true
    window.open(`${this.twitter}`, '_blank')
  }
  @action.bound joinWaggleTwitter() {
    this.waggleTwitterState = true
    window.open(`https://twitter.com/wagglenetwork`, '_blank')
  }

  @action.bound joinDiscord() {
    this.enableNextStepDiscord = true
    window.open(`${this.discord}`, '_blank')
  }

  @asyncAction *submitTelegramTask() {
    try {
      this.loadingTelegramDialog = true
      const tasks = get(this.apply, 'tasks')
      const remainingStatus = this.isEnableDiscordTask
        ? tasks?.discord?.isDone && tasks?.twitter?.isDone
        : tasks?.discord?.isDone
      const apply = yield apiService.applies.update(this.apply?.id, {
        status: remainingStatus ? 'done-tasks' : this.apply.status,
        investorId: this.investor?.id,
        tasks: {
          ...tasks,
          telegram: {
            isDone: true,
            telegramUser: this.telegramUser,
          },
        },
      })
      this.changeModel(apply)
      yield this.fetchReferral()
      // yield this.fetchApply()
      this.telegramTaskDialog = false
      snackController.success('Done telegram task!')
    } catch (e) {
      try {
        const errorMsg = this.getServerError('submitTelegramTask', e)
        apiService.logs.create({
          investor: this.investor?.id,
          user: this.userInfo?.id,
          error: errorMsg,
          data: JSON.stringify(e),
        })
      } catch (err) {
        // TODO
      }
      snackController.commonError(e)
    } finally {
      this.loadingTelegramDialog = false
    }
  }

  @action.bound retweet() {
    window.open(
      this.twitterShareLink || `https://twitter.com/intent/tweet?hashtags=${this.formattedPoolName}`,
      '_blank'
    )
  }
  @action.bound retweet2() {
    window.open(
      this.twitterShareLink2 || `https://twitter.com/intent/tweet?hashtags=${this.formattedPoolName}`,
      '_blank'
    )
  }

  @computed get formattedPoolName() {
    return this.poolName?.replace(/\s/g, '') || ''
  }

  @action checkRetweet1Link(retweetLink) {
    try {
      this.retweet1LinkError = ''
      if (!retweetLink) {
        this.retweet1LinkError = 'Invalid link'
        return false
      }
      const url = new URL(retweetLink)
      const pathName = url.pathname.replace(/(^\/+|\/+$)/gm, '')
      const paths = pathName.split('/')
      if (url.host !== 'twitter.com' || paths.length === 0 || !paths[0]) {
        this.retweet1LinkError = 'Invalid link'
        return false
      }
      this.retweet1LinkError = ''
      return true
    } catch (e) {
      this.retweet1LinkError = 'Invalid link'
    }
    return false
  }
  @action checkRetweet2Link(retweetLink) {
    try {
      this.retweet2LinkError = ''
      if (!retweetLink) {
        this.retweet2LinkError = 'Invalid link'
        return false
      }
      const url = new URL(retweetLink)
      const pathName = url.pathname.replace(/(^\/+|\/+$)/gm, '')
      const paths = pathName.split('/')
      if (url.host !== 'twitter.com' || paths.length === 0 || !paths[0]) {
        this.retweet2LinkError = 'Invalid link'
        return false
      }
      if (this.retweetLink1 === retweetLink) {
        this.retweet2LinkError = 'Duplicate link'
        return false
      }
      this.retweet2LinkError = ''
      return true
    } catch (e) {
      this.retweet2LinkError = 'Invalid link'
    }
    return false
  }
  @action submitRetweet1(link) {
    this.retweetLink1 = link
    this.currentStep += 1
  }
  @action submitRetweet2(link) {
    this.retweetLink2 = link
    this.currentStep += 1
  }

  @asyncAction *submitTwitterTask() {
    try {
      this.loadingTwitterDialog = true
      const tasks = get(this.apply, 'tasks')
      const remainingStatus = this.isEnableDiscordTask
        ? tasks?.discord?.isDone && tasks?.telegram?.isDone
        : tasks?.telegram?.isDone
      const twitterPayload = this.hasTwoTwitterShareLink
        ? { isDone: true, retweetLink1: this.retweetLink1, retweetLink2: this.retweetLink2 }
        : { isDone: true, retweetLink1: this.retweetLink1 }
      const apply = yield apiService.applies.update(this.apply?.id, {
        status: remainingStatus ? 'done-tasks' : this.apply.status,
        investorId: this.investor?.id,
        tasks: {
          ...tasks,
          twitter: twitterPayload,
        },
      })
      this.changeModel(apply)
      yield this.fetchReferral()
      this.changeTwitterTaskDialog(false)
      snackController.success('Done twitter task!')
    } catch (e: any) {
      try {
        const errorMsg = this.getServerError('submitTwitterTask', e)
        apiService.logs.create({
          investor: this.investor?.id,
          user: this.userInfo?.id,
          error: errorMsg,
          data: JSON.stringify(e),
        })
      } catch (err) {
        // TODO
      }
      snackController.commonError(e)
    } finally {
      this.loadingTwitterDialog = false
    }
  }

  @action.bound referralTwitterShare() {
    window.open(
      `https://twitter.com/intent/tweet?text=${'I have found an amazing project on Waggle. Join with me!'}&url=${
        this.twitterShareLink || this.twitter || 'https://twitter.com/wagglenetwork'
      }`
    )
  }

  @action.bound referralTelegramShare() {
    window.open(
      `https://t.me/share/url?url=${
        this.telegram || 'https://t.me/waggle_network'
      }&text=${'I have found an amazing project on Waggle. Join with me!'}`,
      '_blank'
    )
  }

  @computed get telegramFirstStep() {
    return {
      title: this.poolName,
      subtitle: `Join ${this.poolName}`,
    }
  }
  @computed get telegramSecondStep() {
    return {
      title: 'Waggle',
      subtitle: 'Join Waggle',
    }
  }
  @computed get telegramThirdStep() {
    return {
      title: 'Confirm',
      subtitle: 'Receive 1x Ticket',
    }
  }
  @computed get twitterSteps() {
    return (
      (this.hasTwoTwitterShareLink
        ? [
            { step: 1, title: this.poolName, text: 'Twitter follow', ticketNumber: 1 },
            { step: 2, title: 'Waggle', text: 'Twitter follow', ticketNumber: 1 },
            { step: 3, title: 'Waggle', text: 'Like & Retweet 1', ticketNumber: 1 },
            { step: 4, title: 'Waggle', text: 'Like & Retweet 2', ticketNumber: 1 },
            { step: 5, title: '', text: '', ticketNumber: 4 },
          ]
        : [
            { step: 1, title: this.poolName, text: 'Twitter follow', ticketNumber: 1 },
            { step: 2, title: 'Waggle', text: 'Twitter follow', ticketNumber: 1 },
            { step: 3, title: 'Waggle', text: 'Like & Retweet', ticketNumber: 2 },
            { step: 4, title: '', text: '', ticketNumber: 4 },
          ]) || []
    )
  }
  @computed get discordSteps() {
    return (
      [
        { step: 1, title: this.poolName, text: 'Join discord', ticketNumber: 1 },
        { step: 2, title: '', text: '', ticketNumber: 1 },
      ] || []
    )
  }
  @computed get telegramSteps() {
    return (
      [
        { step: 1, title: this.poolName, text: `Join ${this.poolName}`, ticketNumber: 1 },
        { step: 2, title: 'Waggle', text: `Join Waggle`, ticketNumber: 1 },
        { step: 3, title: '', text: '', ticketNumber: 2 },
      ] || []
    )
  }

  @computed get twitterSecondStep() {
    return {
      title: 'Waggle',
      subtitle: 'Follow Waggle',
    }
  }
  @computed get twitterThirdStep() {
    return {
      title: 'Tweet',
      subtitle: 'Receive 1x Ticket',
    }
  }

  @computed get discordFirstStep() {
    return {
      title: this.poolName,
      subtitle: `Join ${this.poolName}`,
    }
  }
  @computed get discordSecondStep() {
    return {
      title: 'Confirm',
      subtitle: 'Receive 1x Ticket',
    }
  }

  @computed get telegramBot() {
    return process.env.VUE_APP_TELEGRAM_BOT_ENV === 'production' ? 'waggleNetworkbot' : 'waggleDevBot'
  }

  @computed get isCommunityRound() {
    return this.isWhitelistEnd && this.apply?.isCommunityRound
  }
  @computed get isTierRound() {
    return this.isWhitelisted && (this.isGuaranteeTier || this.apply?.isTierRound)
  }

  @computed get whitelistStartDate() {
    return this.poolStore?.whitelistStartDate
  }
  @computed get whitelistEndDate() {
    return this.poolStore?.whitelistEndDate
  }
  @computed get publicWhitelistDate() {
    return this.poolStore?.publicWhitelistDate || ''
  }
  @computed get publicWhitelistDateFormat() {
    return moment(this.publicWhitelistDate).format('DD/MM/YYYY HH:mm')
  }
  @computed get startDate() {
    return this.poolStore?.pool.startDate || ''
  }

  @computed get comunityStartDate() {
    if (!this.startDate) return ''
    return moment(this.startDate).add(1, 'day')
  }
  @computed get endDate() {
    return this.poolStore?.pool.endDate
  }
  @computed get isWhitelistStarted() {
    return this.poolStore?.poolState?.isWhitelistStarted
  }

  @computed get extendKycEndDate() {
    return this.poolStore?.extendKycEndDate
  }

  @computed get isLoading() {
    return this.loadingPage || this.loadingPool
  }

  @computed get isValidAccount() {
    return walletStore.walletConnected && this.correctChain
  }

  @computed get isWhitelistEnd() {
    return this.poolStore?.poolState?.isWhitelistEnd
  }
  @computed get isSaleStarted() {
    return this.poolStore?.poolState?.started
  }
  @computed get isSaleEnd() {
    return this.poolStore?.poolState?.ended
  }
  @computed get isWhitelistPublic() {
    return this.poolStore?.poolState?.isWhitelistPublic
  }
  @computed get isKycEnd() {
    return this.poolStore?.poolState?.isKycEnd
  }
  @computed get whitelistConfig() {
    return this.poolStore?.whitelistConfig
  }
  @computed get kycEndDate() {
    return this.whitelistConfig?.kycEndDate ? moment(this.whitelistConfig?.kycEndDate) : ''
  }
  @computed get kycEndDateFormat() {
    return moment(this.kycEndDate).format('DD/MM/YYYY HH:mm')
  }
  @computed get socialTicket() {
    const telegramDone = get(this.apply, 'tasks.telegram.isDone')
    const twitterDone = get(this.apply, 'tasks.twitter.isDone')
    const discordDone = get(this.apply, 'tasks.discord.isDone')
    let count = this.referralCount
    if (telegramDone) count += 2
    if (twitterDone) count += 4
    if (discordDone) count += 1
    count = this.tierType === 1 ? count + 5 : this.tierType === 2 ? count + 10 : count
    return count
  }
  @computed get isDoneTwitter() {
    return this.apply?.tasks?.twitter?.isDone || false
  }
  @computed get isDoneTelegram() {
    return this.apply?.tasks?.telegram?.isDone || false
  }
  @computed get isEnableDiscordTask() {
    return !!this.pool?.data?.discord
  }
  @computed get isDoneDiscordTask() {
    return (this.isEnableDiscordTask && this.apply?.tasks?.discord?.isDone) || false
  }
  @computed get referralLink() {
    return this.apply?.data?.referralLink || ''
  }
  @computed get isLogin() {
    return !!this.jwtJson
  }
  @computed get stakedAmount() {
    return this.farmStakedAmount.toString()
  }

  @computed get isWhitelisted() {
    return this.apply?.status === 'whitelisted'
  }

  @computed get isExtendKyc() {
    return (
      !this.isKycVerified &&
      this.isKycEnd &&
      this.isBeforeExtendKyc &&
      this.isWinnerNoStaking &&
      this.apply?.status === 'whitelisted'
    )
  }
  @computed get isUserVerifiedKyc() {
    return this.investor?.status === 'kyc-verified'
  }
  @computed get isBeforeExtendKyc() {
    return this.extendKycEndDate && this.poolStore?.poolState?.currentTime.isBefore(this.extendKycEndDate)
  }

  @computed get chain() {
    return this.poolStore?.pool?.chain
  }

  @computed get tokenAddress() {
    return this.poolStore?.pool?.tokenAddress || 'TBA'
  }
  @computed get chainId() {
    return this.poolStore?.pool?.chainId
  }

  @computed get ratio() {
    return this.poolStore?.pool?.ratio
  }
  @computed get discount() {
    return this.poolStore?.pool?.data?.discount
  }

  @computed get isTBAWhitelistStartDate() {
    return this.whitelistConfig?.isTBAWhitelistStartDate
  }
  @computed get isTBAWhitelistEndDate() {
    return this.whitelistConfig?.isTBAWhitelistEndDate
  }
  @computed get isTBAWhitelistPublicDate() {
    return this.whitelistConfig?.isTBAWhitelistPublicDate
  }

  @computed get isLedger() {
    return this.userInfo?.data?.isLedger
  }

  @computed get isTBAKYCEndDate() {
    return this.whitelistConfig?.isTBAKYCEndDate
  }
  @computed get isTBASaleStartDate() {
    return this.poolStore?.pool?.data?.isTBASaleStartDate
  }
  @computed get isTBASaleEndDate() {
    return this.poolStore?.pool?.data?.isTBASaleEndDate
  }
  @computed get isTBASale() {
    return this.poolStore?.isTBASale
  }

  @computed get isApplied() {
    return !!this.apply
  }
  @computed get isVisibleWhitelistResult() {
    return this.isWhitelistPublic && this.isValidAccount
  }

  @computed get correctChain() {
    return this.chainId === walletStore.chainId
  }
  @computed get isVisibleSocialWhitelist() {
    return !this.loadingPage && this.isWhitelistStarted && !this.isWhitelistEnd && this.isApplied
  }
  @computed get isTBACountDown() {
    return this.poolStore?.poolState?.isTBACountDown || false
  }
  @computed get countdownSeconds() {
    return this.poolStore?.poolState?.countdownSeconds || 0
  }
  @computed get countdownMinutes() {
    return this.poolStore?.poolState?.countdownMinutes || 0
  }
  @computed get countdownHours() {
    return this.poolStore?.poolState?.countdownHours || 0
  }
  @computed get countdownDays() {
    return this.poolStore?.poolState?.countdownDays || 0
  }
  @computed get pool() {
    return this.poolStore?.pool
  }
  @computed get poolId() {
    return this.poolStore?.pool.id
  }
  @computed get poolName() {
    return this.pool?.name || ''
  }
  @computed get tradeToken() {
    return this.poolStore?.tradeToken
  }
  @computed get description() {
    return this.pool?.description || this.pool?.data?.shortDescription
  }
  @computed get totalTokens() {
    return this.poolStore?.totalTokens
  }
  @computed get totaRaiseToUsd() {
    return this.poolStore?.totaRaiseToUsd
  }
  @computed get tokenName() {
    return this.pool?.tokenName || ''
  }
  @computed get logoImage() {
    return this.pool?.logoUrl || this.pool?.file
  }
  @computed get medium() {
    return this.poolStore?.medium
  }
  @computed get discord() {
    return this.poolStore?.discord
  }
  @computed get twitterShareLink() {
    return this.poolStore?.twitterShareLink
  }
  @computed get twitterShareLink2() {
    return this.poolStore?.twitterShareLink2
  }

  @computed get hasTwoTwitterShareLink() {
    return !!this.twitterShareLink2
  }
  @computed get telegram() {
    return this.poolStore?.telegram
  }
  @computed get announcementTelegram() {
    return this.poolStore?.announcementTelegram
  }
  @computed get twitter() {
    return this.poolStore?.twitter
  }
  @computed get web() {
    return this.poolStore?.web
  }
  @computed get facebook() {
    return this.poolStore?.facebook
  }
  @computed get reddit() {
    return this.poolStore?.reddit
  }
  @computed get twitch() {
    return this.poolStore?.twitch
  }
  @computed get youtube() {
    return this.poolStore?.youtube
  }
  @computed get tiktok() {
    return this.poolStore?.tiktok
  }
  @computed get instagram() {
    return this.poolStore?.instagram
  }
  @computed get blog() {
    return this.poolStore?.blog
  }
  @computed get linkedIn() {
    return this.poolStore?.linkedIn
  }

  @computed get hasStakeTier() {
    return !!this.tierType && this.chain === 'sol'
  }
  @computed get loadingPage() {
    return this.loadingCounter > 0
  }

  @computed get currentStepWhitelist() {
    if (
      (this.isGuaranteeTier && this.isGuaranteeTierNextStep && !this.isKycEnd) ||
      (this.isExtendKyc && this.isWinnerNoStaking)
    )
      return 3
    if (!this.isWhitelistStarted) return 0
    else if (!this.isWhitelistEnd) return 1
    else if (!this.isWhitelistPublic) return 2
    else if (!this.isKycEnd) return 3
    else if (!this.isSaleStarted) return 4
    else return 5
  }

  @computed get getCountdownTargetDate() {
    return this.poolStore?.poolState?.countdownTargetDate || ''
  }

  @computed get getCountdownText() {
    return this.poolStore?.poolState?.countdownText || ''
  }

  @computed get isVisibleTelegramStep1() {
    return (
      this.telegramUser &&
      this.poolCommunityTelegramState &&
      (!this.announcementTelegram || this.poolAnnouncementTelegramState)
    )
  }
  @computed get isVisibleTelegramStep2() {
    return this.waggleCommunityTelegramState && this.waggleAnnouncementTelegramState && this.waggleInsightsTelegramState
  }
  @computed get isVisibleTwitterStep1() {
    return this.poolTwitterState
  }
  @computed get isVisibleTwitterStep2() {
    return this.waggleTwitterState && this.waggleInsightsTwitterState
  }

  @computed get isMultiToken() {
    return this.pool?.data?.isMultiToken
  }
  @computed get tokenList() {
    return this.pool?.data?.tokenList || []
  }
}
