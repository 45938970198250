





































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { IdoApplyViewModel } from '../viewmodels/ido-apply-viewmodel'

@Observer
@Component({})
export default class ConfirmConfigRegisterDialog extends Vue {
  @Inject() vm!: IdoApplyViewModel
  walletStore = walletStore

  confirm() {
    this.vm.changeConfirmConfigRegisterDialog(false)
    this.vm.changeRegisterDialog(true)
  }
}
