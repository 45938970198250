
























































































































































import { Component, Vue, Inject, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { walletStore } from '@/stores/wallet-store'
import { IdoApplyViewModel } from '../viewmodels/ido-apply-viewmodel'
Observer
@Component({
  components: {},
})
export default class SocialWhitelist extends Vue {
  @Prop({ default: false }) isLight
  @Prop({ default: false }) isTelegramTaskDone
  @Prop({ default: false }) isTwitterTaskDone
  @Prop({ default: false }) isDiscordTaskDone

  @Inject() vm!: IdoApplyViewModel
  walletStore = walletStore
  copyAddress() {
    navigator.clipboard.writeText(this.vm.referralLink)
  }
}
