var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"black-bg pb-6 text-center"},[_c('div',{staticClass:"card-bg py-6 token-number text-uppercase"},[_vm._v("Waggle Community Pool")]),_c('div',{staticClass:"fill-width d-flex justify-center"},[_c('div',{staticClass:"text-start d-flex flex-column align-center justify-center",class:_vm.$vuetify.breakpoint.mdAndUp ? '' : 'mx-6',staticStyle:{"max-width":"700px"}},[_c('div',{staticClass:"text-start my-6",staticStyle:{"font-size":"20px","font-weight":"600"}},[_vm._v(" You will be registered whitelist with: ")]),_c('div',{staticClass:"fill-width",class:{
          'd-flex justify-space-between': _vm.$vuetify.breakpoint.mdAndUp,
          'd-flex flex-column align-center': !_vm.$vuetify.breakpoint.mdAndUp,
        }},[_c('card-hover',{staticClass:"pa-4",class:{
            'light-black-bg': _vm.$vuetify.theme.dark,
            'card-info-desktop': _vm.$vuetify.breakpoint.mdAndUp,
            'card-info-mobile mb-6': !_vm.$vuetify.breakpoint.mdAndUp,
          },staticStyle:{"border-width":"1px !important","border-radius":"16px !important"},attrs:{"rounded":"rounded-lg","disableHover":true}},[_c('div',{staticClass:"text-caption"},[_vm._v("Your winning wallet")]),_c('div',{staticClass:"mt-2 text-body-2 font-weight-bold apply-word-break"},[_vm._v(" "+_vm._s(_vm.walletStore.account)+" ")]),_c('div',{staticClass:"mt-4 text-caption"},[_vm._v("Your Round")]),_c('div',{staticClass:"mt-2 text-body-2 font-weight-bold"},[_vm._v(" Waggle Tier ("+_vm._s(_vm.vm.tierType === 3 ? 'Drone Bee' : 'Queen Bee')+") ")])]),_c('div',{staticClass:"border-tier fill-width",class:{
            'card-tier-desktop': _vm.$vuetify.breakpoint.mdAndUp,
            'card-tier-mobile': !_vm.$vuetify.breakpoint.mdAndUp,
          }},[_c('v-card',{staticClass:"card-bg elevation-0 d-flex align-center justify-space-between project-stake-tier pa-6 border-radius-8"},[_c('div',{staticClass:"text-start full-height d-flex flex-column justify-start"},[_c('div',[_c('div',[_vm._v("Your Tier")]),_c('div',{staticClass:"timeline-title"},[_vm._v(_vm._s(_vm.vm.userTier))])])]),(_vm.vm.tierImage)?_c('v-img',{attrs:{"src":_vm.vm.tierImage,"max-width":"108px"}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-flex justify-center my-6"},[_c('v-btn',{staticClass:"text-none btn-text",attrs:{"outlined":"","color":"primary","rounded":""},on:{"click":function($event){return _vm.vm.guaranteeTierNextStepOnChange(true)}}},[_vm._v(" Next Step ")])],1),_c('card-hover',{staticClass:"pa-6 border-radius-8",class:_vm.$vuetify.theme.dark ? 'light-black-bg' : '',staticStyle:{"border-width":"1px !important"},attrs:{"rounded":"rounded-lg","disableHover":true}},[_c('div',{staticClass:"d-flex justify-center text-center",staticStyle:{"font-weight":"600","font-size":"20px","max-width":"600px"}},[_vm._v(" Let’s follow social channels not to miss any project and waggle announcements! ")]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-img',{staticClass:"cursor-pointer",class:_vm.isTelegramTaskDone ? 'disabled-button' : '',style:(_vm.$vuetify.breakpoint.smAndDown ? 'max-width: 250px ' : ''),attrs:{"contain":"","src":_vm.isTelegramTaskDone
                  ? require('@/assets/images/finish-task.svg')
                  : require('@/assets/images/unfinish-task.svg')},on:{"click":function($event){return _vm.vm.changeTelegramTaskDialog(true)}}},[_c('div',{staticClass:"d-flex ml-6 align-center full-height text-start"},[(_vm.isTelegramTaskDone)?_c('div',{staticClass:"done-task-circle d-flex justify-center align-center"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-check")])],1):_c('v-img',{attrs:{"src":require('@/assets/icons/telegram-logo.svg'),"max-width":"36px","contain":""}}),_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"project-social-text"},[_vm._v("Telegram Task")]),_c('div',{staticClass:"lightGray--text"},[_vm._v(_vm._s(_vm.isTelegramTaskDone ? 2 : 0)+"/2 Ticket Collected")])])],1)])],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-img',{staticClass:"cursor-pointer",class:_vm.isTwitterTaskDone ? 'disabled-button' : '',style:(_vm.$vuetify.breakpoint.smAndDown ? 'max-width: 250px ' : ''),attrs:{"src":_vm.isTwitterTaskDone
                  ? require('@/assets/images/finish-task.svg')
                  : require('@/assets/images/unfinish-task.svg'),"contain":""},on:{"click":function($event){return _vm.vm.changeTwitterTaskDialog(true)}}},[_c('div',{staticClass:"d-flex ml-6 align-center full-height text-start"},[(_vm.isTwitterTaskDone)?_c('div',{staticClass:"done-task-circle d-flex justify-center align-center"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-check")])],1):_c('v-img',{attrs:{"src":require('@/assets/icons/twitter.svg'),"max-width":"36px","contain":""}}),_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"project-social-text"},[_vm._v("Twitter Task")]),_c('div',{staticClass:"lightGray--text"},[_vm._v(_vm._s(_vm.isTwitterTaskDone ? 4 : 0)+"/4 Ticket Collected")])])],1)])],1),(_vm.vm.isEnableDiscordTask && _vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"md":"3","cols":"0"}}):_vm._e(),(_vm.vm.isEnableDiscordTask)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-img',{staticClass:"cursor-pointer",class:_vm.isDiscordTaskDone ? 'disabled-button' : '',style:(_vm.$vuetify.breakpoint.smAndDown ? 'max-width: 250px ' : ''),attrs:{"contain":"","src":_vm.isDiscordTaskDone
                  ? require('@/assets/images/finish-task.svg')
                  : require('@/assets/images/unfinish-task.svg')},on:{"click":function($event){return _vm.vm.changeDiscordTaskDialog(true)}}},[_c('div',{staticClass:"d-flex ml-6 align-center full-height text-start"},[(_vm.isDiscordTaskDone)?_c('div',{staticClass:"done-task-circle d-flex justify-center align-center"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-check")])],1):_c('v-img',{attrs:{"src":require('@/assets/icons/discord-logo.svg'),"max-width":"36px","contain":""}}),_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"project-social-text"},[_vm._v("Discord Task")]),_c('div',{staticClass:"lightGray--text"},[_vm._v(_vm._s(_vm.isDiscordTaskDone ? 1 : 0)+"/1 Ticket Collected")])])],1)])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}})],1),_c('v-divider',{staticClass:"my-6"}),_c('div',{staticClass:"d-flex justify-center text-center",staticStyle:{"font-weight":"600","font-size":"20px"}},[_vm._v(" And free share with your friends and community together! ")]),_c('div',{staticClass:"text-body-1 font-weight-medium d-flex justify-center mt-6"},[_vm._v("Referral: "+_vm._s(_vm.vm.referralCount))]),_c('card-hover',{staticClass:"pa-4 d-flex align-center justify-space-between ma-4 apply-word-break",attrs:{"disableHover":true}},[_vm._v(" "+_vm._s(_vm.vm.referralLink)+" "),_c('address-copy-board',{attrs:{"iconSize":16,"index":1,"address":_vm.vm.referralLink,"isVisibleValue":false}})],1),_c('v-row',{staticClass:"d-flex justify-center mt-2 px-6"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{staticClass:"project-social-text py-6 elevation-0",attrs:{"color":"primary","rounded":"","block":""},on:{"click":_vm.vm.referralTelegramShare}},[_vm._v(" Telegram Share ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{staticClass:"project-social-text py-6 elevation-0",attrs:{"color":"primary","rounded":"","block":""},on:{"click":_vm.vm.referralTwitterShare}},[_vm._v(" Twitter Share ")])],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }