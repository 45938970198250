var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-6",class:{ 'card-bg': _vm.isLight, 'black-bg': !_vm.isLight }},[(!_vm.vm.isWhitelisted)?_c('div',{staticClass:"d-flex flex-column align-center text-center"},[_c('div',{staticClass:"section-title"},[_vm._v("It's pity that you do not have a winning ticket!")]),_c('div',{staticClass:"section-content mt-4 message-width"},[_vm._v(" You are not on the whitelist so you cannot participate in the sale, but thank you for your interest! ")])]):(
      _vm.vm.isKycVerified &&
      ((!_vm.vm.isWinnerNoStaking && _vm.vm.isValidKycPassTime) || (_vm.vm.isWinnerNoStaking && _vm.vm.isValidExtendKycPassTime))
    )?_c('div',{staticClass:"d-flex flex-column align-center text-center"},[_c('div',{staticClass:"section-title"},[_vm._v("Congratulations, You have passed KYC")]),_c('div',{staticClass:"section-content message-width mt-6"},[_vm._v(" You are qualified to participate the sale. Please prepare for the sale that will start in "+_vm._s(_vm._f("ddmmyyyyhhmm")(_vm.vm.startDate))+" ")]),_c('div',{staticClass:"section-content mt-4 message-width"},[_vm._v(" The sale will take place on "),_c('a',{on:{"click":function($event){return _vm.$router.push(("/project/" + (_vm.vm.unicodeName)))}}},[_vm._v("project detail")]),_vm._v(" page on Waggle ")]),(_vm.vm.isTierRound || _vm.vm.isCommunityRound)?_c('div',{staticClass:"d-flex mt-6",class:_vm.$vuetify.breakpoint.mdAndUp ? 'flex-row' : 'flex-column'},[_c('card-hover',{staticClass:"pa-4 d-flex",class:{
          'fill-width': _vm.$vuetify.breakpoint.xs,
          'mr-4': _vm.$vuetify.breakpoint.mdAndUp,
        },staticStyle:{"border-width":"1px !important","border-radius":"16px !important"},attrs:{"rounded":"rounded-lg","disableHover":true}},[_c('v-img',{attrs:{"src":require("@/assets/icons/tier-round-icon.svg"),"contain":"","max-width":"44"}}),_c('div',{staticClass:"ml-4"},[_c('div',{staticClass:"text-caption font-weight-thin mb-1 text-start"},[_vm._v("Your pool")]),_c('div',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.vm.isTierRound ? 'Waggle Tier Pool' : _vm.vm.isCommunityRound ? 'Waggle Community pool' : '')+" ")])])],1),_c('card-hover',{staticClass:"pa-4 d-flex",class:{
          'mt-6 fill-width': !_vm.$vuetify.breakpoint.mdAndUp,
          'fill-width': _vm.$vuetify.breakpoint.xs,
        },staticStyle:{"border-width":"1px !important","border-radius":"16px !important"},attrs:{"rounded":"rounded-lg","disableHover":true}},[_c('v-img',{attrs:{"src":require("@/assets/icons/clock-circle-icon.svg"),"contain":"","max-width":"44"}}),_c('div',{staticClass:"ml-4"},[_c('div',{staticClass:"text-caption font-weight-thin mb-1 text-start"},[_vm._v("Pool opening time")]),_c('div',{staticClass:"text-body-2 font-weight-bold apply-word-break"},[_vm._v(" "+_vm._s(_vm._f("ddmmyyyyhhmm")(_vm.vm.isWinnerNoStaking ? _vm.vm.comunityStartDate : _vm.vm.startDate))+" ")])])],1)],1):_vm._e()]):_c('div',{staticClass:"d-flex flex-column align-center section-title px-6"},[_c('div',{staticClass:"section-title mb-6"},[_vm._v("Sorry, you haven't completed KYC in time")]),_c('div',{staticClass:"section-content message-width text-start"},[(_vm.vm.kycRejectedReasons.length > 0)?_c('div',{staticClass:"text-start"},[_vm._v(" The KYC step is closed. Some error happened in the KYC: "),_c('ul',{staticClass:"mt-1 mb-4"},_vm._l((_vm.vm.kycRejectedReasons),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"section-content mb-1"},[_vm._v(" You have not completed KYC before "+_vm._s(_vm._f("ddmmyyyyhhmm")((_vm.vm.isWinnerNoStaking ? _vm.vm.extendKycEndDate : _vm.vm.kycEndDate)))+", so you cannot participate in the sale ")]),_c('div',{staticClass:"section-content text-center"},[_vm._v(" Thank you for your interest in this project! See you in future projects ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }