






































































































































































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject, Prop } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { IdoApplyViewModel } from '../viewmodels/ido-apply-viewmodel'
import WhitelistFinalResult from './whitelist-final-result.vue'

@Observer
@Component({
  components: {
    WhitelistFinalResult,
  },
})
export default class StakingLPDialog extends Vue {
  @Inject() vm!: IdoApplyViewModel
  @Prop({ default: false }) hasWinningTicket
  @Prop({ default: null }) startSaleDate?: Date
  @Prop({ default: false }) isLight
  walletStore = walletStore
  goToSubmitKYC() {
    this.$router.push('/submit-kyc')
  }
}
