































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { IdoPoolDetailViewModel } from '../viewmodels/ido-pool-detail-viewmodel'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({})
export default class StakingLPDialog extends Vue {
  @Inject() vm!: IdoPoolDetailViewModel
  walletStore = walletStore
}
