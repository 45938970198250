




































































































































































































































































































































































import Countdown from '@/components/countdown.vue'
import FeaturedPoolItem from '../components/featured-pool-item.vue'
import UpcomingPoolItem from '../components/upcoming-pool-item.vue'
import ComingSoon from '@/modules/common/pages/coming-soon.vue'
import TelegramLoginDialog from '@/modules/ido/components/telegram-login-dialog.vue'
import RegisterWhitelistDialog from '@/modules/ido/components/register-whitelist-dialog.vue'
import RegisterWhitelist from '@/modules/ido/components/register-whitelist.vue'
import TaskTwitterDialog from '@/modules/ido/components/task-twitter-dialog.vue'
import TaskTelegramDialog from '@/modules/ido/components/task-telegram-dialog.vue'
import TaskDiscordDialog from '@/modules/ido/components/task-discord-dialog.vue'
import SocialWhitelist from '../components/social-whitelist.vue'
import SocialWhitelistGuarantee from '../components/social-whitelist-guarantee.vue'
import WhitelistResult from '../components/whitelist-result.vue'

import { IdoApplyViewModel } from '../viewmodels/ido-apply-viewmodel'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Inject, Watch } from 'vue-property-decorator'
import { AppProvider } from '@/app-providers'
@Observer
@Component({
  components: {
    Countdown,
    UpcomingPoolItem,
    FeaturedPoolItem,
    ComingSoon,
    TelegramLoginDialog,
    RegisterWhitelistDialog,
    TaskTelegramDialog,
    TaskDiscordDialog,
    TaskTwitterDialog,
    SocialWhitelist,
    WhitelistResult,
    RegisterWhitelist,
    SocialWhitelistGuarantee,
  },
})
export default class IdoPools extends Vue {
  @Inject() providers!: AppProvider

  @Provide() vm = new IdoApplyViewModel()
  walletStore = walletStore
  steps = [
    {
      title: 'Preparation',
      description: 'This project is in the preparation phase. Please stay tuned.',
    },
    {
      title: 'Whitelist',
      description: 'The whitelist is open for registrations.',
    },
    {
      title: 'Waggle Ticket',
      description: 'If you have any winning tickets, you will be whitelisted here.',
    },
    {
      title: 'Participants Announcement',
      description: 'Announce investor list qualified to participate the sale.',
    },
    {
      title: 'Sales Phase',
      description: 'Winners can participate in the token sale.',
    },
  ]

  @Watch('$route.params.unicodeName', { immediate: true }) async onPoolIdChanged(val: string) {
    if (val) {
      await this.vm.loadPool({
        unicodeName: val,
        refLinkFirst: this.$route.params?.refLink,
        refLinkSecond: this.$route.query?.ref,
      })
    }
  }

  destroyed() {
    this.vm.destroy()
  }
}
