import { render, staticRenderFns } from "./whitelist-final-result.vue?vue&type=template&id=6bb7330e&scoped=true&"
import script from "./whitelist-final-result.vue?vue&type=script&lang=ts&"
export * from "./whitelist-final-result.vue?vue&type=script&lang=ts&"
import style0 from "./whitelist-final-result.vue?vue&type=style&index=0&id=6bb7330e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bb7330e",
  null
  
)

export default component.exports