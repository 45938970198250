










































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import TokenIcon from '@/components/images/token-icon.vue'
import VueRecaptcha from 'vue-recaptcha'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import axios from 'axios'
import { AppProvider } from '@/app-providers'
import { IdoApplyViewModel } from '../viewmodels/ido-apply-viewmodel'

@Observer
@Component({
  components: {
    TokenIcon,
    VueRecaptcha,
  },
})
export default class StakingLPDialog extends Vue {
  @Inject() providers!: AppProvider
  @Inject() vm!: IdoApplyViewModel
  walletStore = walletStore
  template = ''
  agreeTerm = false
  confirmAgreeTerm = false
  confirmCaptcha = false

  async mounted() {
    let templateData = ''
    if (this.providers.lightmode) {
      const template = await axios.get('../../../light-term-and-condition.html')
      templateData = template.data
    } else {
      const template = await axios.get('../../../term-and-condition.html')
      templateData = template.data
    }
    this.template = templateData
  }

  closeDialog() {
    this.reset()
    if (this.$refs.recaptcha) (this.$refs.recaptcha as any).reset()
    this.vm.changeRegisterDialog(false)
  }
  confirmTerm() {
    if ((this.$refs.form as any).validate()) this.confirmAgreeTerm = true
  }
  reset() {
    this.agreeTerm = false
    this.confirmAgreeTerm = false
    this.confirmCaptcha = false
  }
  onCaptchaError(error) {
    snackController.error(error)
  }
  onCaptchaRender() {
    ;(this.$refs.recaptcha as any).reset()
  }
  onCaptchaVerify(response) {
    if (response) this.confirmCaptcha = true
  }
  onCaptchaExpired() {
    ;(this.$refs.recaptcha as any).reset()
  }
}
