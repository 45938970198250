





































































































































































































import { IdoApplyViewModel } from '../viewmodels/ido-apply-viewmodel'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { AppProvider } from '@/app-providers'
import ConnectSolanaWallet from './connect-solana-wallet.vue'
import ConfirmConfigRegisterDialog from './confirm-config-register-dialog.vue'
@Observer
@Component({
  components: {
    ConnectSolanaWallet,
    ConfirmConfigRegisterDialog,
  },
})
export default class IdoPools extends Vue {
  @Inject() providers!: AppProvider
  @Inject() vm!: IdoApplyViewModel
  walletStore = walletStore
}
