var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-6",class:_vm.$vuetify.theme.dark ? '' : 'grey1--text'},_vm._l((_vm.taskSteps),function(item,index){return _c('div',{key:index},[(index < _vm.taskSteps.length - 1)?_c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"step-number d-flex align-center justify-center mr-4",class:{
            'normal-step-border': _vm.currentStep < index,
            'success-border': _vm.currentStep > index,
            'primary-border': _vm.currentStep === index,
          }},[(_vm.currentStep > index)?_c('v-icon',{staticClass:"success-color"},[_vm._v("mdi-check")]):_c('div',{staticClass:"timeline-title"},[_vm._v(_vm._s(item.step))])],1),_c('div',{staticClass:"text-body-1 font-weight-600",class:{
            'primary--text': _vm.currentStep === index,
            'text-caption': _vm.$vuetify.breakpoint.xs,
          }},[_vm._v(" "+_vm._s(item.title)+" ●  ")]),_c('div',{staticClass:"text-body-1 font-weight-400",class:{
            'primary--text': _vm.currentStep === index,
            'text-caption': _vm.$vuetify.breakpoint.xs,
          }},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center py-1 px-3 border-radius-8 text-body-1 font-weight-bold grey-text",class:_vm.currentStep > index ? 'success-bg' : 'ticker-box-gray'},[_vm._v(" +"+_vm._s(item.ticketNumber)+" "),_c('v-icon',{staticClass:"ml-3",attrs:{"medium":""}},[_vm._v("mdi-check-circle-outline")])],1)],1),_c('div',{staticClass:"vertical-divider"})]):_c('div',{staticClass:"d-flex align-center",class:{
        'primary--text': _vm.currentStep === index,
      }},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"step-number d-flex align-center justify-center mr-4",class:{
            'normal-step-border': _vm.currentStep < index,
            'success-border': _vm.currentStep > index,
            'primary-border': _vm.currentStep === index,
          }},[(_vm.currentStep > index)?_c('v-icon',{staticClass:"success-color"},[_vm._v("mdi-check")]):_c('div',{staticClass:"timeline-title"},[_vm._v(_vm._s(item.step))])],1),_c('div',{staticClass:"text-body-1 font-weight-600",class:{
            'text-caption': _vm.$vuetify.breakpoint.xs,
          }},[_vm._v(" Confirm and earn tickets ")])]),_c('v-spacer'),_c('div',{staticClass:"text-body-1",class:{
          'text-caption': _vm.$vuetify.breakpoint.xs,
        }},[_vm._v(" Total "+_vm._s(item.ticketNumber)+" ")])],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }