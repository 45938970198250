import { render, staticRenderFns } from "./task-discord-dialog.vue?vue&type=template&id=5aebd11a&scoped=true&"
import script from "./task-discord-dialog.vue?vue&type=script&lang=ts&"
export * from "./task-discord-dialog.vue?vue&type=script&lang=ts&"
import style0 from "./task-discord-dialog.vue?vue&type=style&index=0&id=5aebd11a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aebd11a",
  null
  
)

export default component.exports