






















































































import { Observer } from 'mobx-vue'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Observer
@Component
export default class Footer extends Vue {
  @Prop({ default: () => [] }) taskSteps
  @Prop({ default: 0 }) currentStep
}
